import {
  getCurrency,
  getLocaleByCode,
} from 'localization';
import { assign } from 'lodash-es';

type NumberFormatOptions = Parameters<typeof Intl.NumberFormat>[1];
type CurrencyFormatOptions = Omit<NumberFormatOptions, 'style'>;
type DateTimeFormatOptions = Parameters<typeof Intl.DateTimeFormat>[1];

const useFormatter = () => {
  const { locale } = useI18n();

  const currentIso = computed(() => getLocaleByCode(locale.value)?.iso);

  const formatCurrency = (value: number, options?: CurrencyFormatOptions) => {
    const defaultOptions = {
      currency: getCurrency(currentIso.value),
      style: 'currency',
    } satisfies NumberFormatOptions;

    return Intl.NumberFormat(currentIso.value, assign(defaultOptions, options)).format(value);
  };

  const formatNumber = (value: number, options?: NumberFormatOptions) => {
    const defaultOptions = { style: 'decimal' } satisfies NumberFormatOptions;

    return Intl.NumberFormat(currentIso.value, assign(defaultOptions, options)).format(value);
  };

  const formatDate = (date: Date, options?: DateTimeFormatOptions) => {
    const defaultOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    } satisfies DateTimeFormatOptions;

    return new Intl.DateTimeFormat(currentIso.value, assign(defaultOptions, options)).format(date);
  };

  const formatDateRange = (startDate: Date, endDate: Date, options?: {
    includeYear?: boolean;
  }) => {
    const start = formatDate(startDate, {
      day: '2-digit',
      month: '2-digit',
      year: options?.includeYear
        ? '2-digit'
        : undefined,
    });

    const end = formatDate(endDate, {
      day: '2-digit',
      month: '2-digit',
      year: options?.includeYear
        ? '2-digit'
        : undefined,
    });

    return `${start} - ${end}`;
  };

  const formatPercentage = (value: number, options?: NumberFormatOptions) => {
    const defaultOptions = {
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
      style: 'percent',
    } satisfies NumberFormatOptions;

    return Intl.NumberFormat(currentIso.value, assign(defaultOptions, options)).format(value);
  };

  return {
    formatCurrency,
    formatDate,
    formatDateRange,
    formatNumber,
    formatPercentage,
  };
};

export { useFormatter };
